/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject, formatDateToLocaleDateString} from "../../utils";

const USER_COMPETENCY_HISTORIES_QUERY = gql`
query {
    userCompetencyHistories {
      _id
      user_id{
           _id
           user_fname
           user_lname
      }
      user_comp_evidence
      user_comp_compId{ 
        _id
        comp_title
      }
      user_comp_catId{ 
        _id
        comp_cat_title
      }
      user_comp_expiry
      user_comp_approverId{ 
        _id
        user_fname
        user_lname
      }
      user_comp_status
      user_comp_date_gained
      user_comp_archived
      history_parent_record_id
      history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
      }
      history_modified_date
      history_modified_date_timestamp
    }
}
`;

function UserCompetencyHistory(props) {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [userCompHistories, setUserCompHistories] = useState([]);

  const [userCompetencyHistories, { loading, error, data: userCompHistoriesData }] = useLazyQuery(USER_COMPETENCY_HISTORIES_QUERY);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    userCompetencyHistories({ variables: {}, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (userCompHistoriesData && userCompHistoriesData.userCompetencyHistories) {
      let histories = formatUserCompHistories(userCompHistoriesData.userCompetencyHistories);
      let reversedHistoryList = histories.reverse();
      setUserCompHistories(reversedHistoryList);
    }
  }, [userCompHistoriesData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'User/Verifier',
        accessor: 'user_fullname'
      },
      {
        Header: 'Competency',
        accessor: 'competency_title',
      },
      {
        Header: 'Category',
        accessor: 'category_title'
      },
      {
        Header: 'Expiry',
        accessor: 'expiry_date',
      },
      {
        Header: 'Status',
        accessor: 'expiry_status',
      },
      {
        Header: 'Modified Date',
        accessor: 'modified_date',
      },
      {
        Header: 'Modified By',
        accessor: 'modified_by'
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, parentId } }) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/user-competency/history-diff/${id}/${parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>

          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">COMPETENCY CATEGORIES Histories<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table detailing changes to Users' competencies.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competencies - Histories</h6>

      <Link
        to={{
          pathname: '/user-competencies',
        }}
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
      </Link>

      <Table columns={columns} data={userCompHistories} />

    </div>
  )
}


function formatUserCompHistories(userCompHistories) {

  let newUserCompHistories = [];
  if (userCompHistories && userCompHistories.length) {
    userCompHistories.forEach(userCompHis => {

      const { user_fname: userFname = "", user_lname: userLname = "" } = userCompHis.user_id ? userCompHis.user_id : {};
      const { user_fname: approverFname = "", user_lname: approverLname = "" } = userCompHis.user_comp_approverId ? userCompHis.user_comp_approverId : {};
      const { user_fname: modifierFname = "", user_lname: modifierLname = "" } = userCompHis.history_modifying_user_id ? userCompHis.history_modifying_user_id : {};

      const { comp_title = "" } = userCompHis.user_comp_compId;
      const { comp_cat_title = "" } = userCompHis.user_comp_catId;

      let options = { year: 'numeric', month: 'long', day: 'numeric' };

      let expiryDate = new Date(userCompHis.user_comp_expiry);
      let expiryDateString = expiryDate.toLocaleDateString('en-GB', options);
      let expiryStatus = getExpirationStatus(userCompHis.user_comp_expiry);

      let modifiedDateString = formatDateToLocaleDateString(userCompHis.history_modified_date) || "N/A";

      let userCompHistory = {
        id: userCompHis._id,
        user_fullname: (userFname + " " + userLname),
        competency_title: comp_title,
        category_title: comp_cat_title,
        expiry_date: expiryDateString,
        approver_fullname: (approverFname + " " + approverLname),
        expiry_status: expiryStatus,
        parentId: userCompHis.history_parent_record_id,
        modified_date: modifiedDateString,
        modified_by: (modifierFname + " " + modifierLname),
      };

      newUserCompHistories.push(userCompHistory);
    })
  }
  return newUserCompHistories;
}

function getExpirationStatus(compExpiryDate) {
    let currentDate = new Date();
    let expiryDate = new Date(compExpiryDate);
    let dateDifference = expiryDate - currentDate;
    let remainingDays = dateDifference / (1000 * 3600 * 24);
  
    if (remainingDays > 90) {
        return 'Valid';
    }
  
    if (remainingDays > 0 && remainingDays < 90) {
        return 'Expiring Soon';
    }
  
    if (remainingDays < 0) {
        return 'Expired';
    }
  }


export default UserCompetencyHistory;
