/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import PageSpinner from "../global/pageSpinner";

const USER_QUERY = gql`
      query User($userId: ID!){
          user(_id: $userId) {
              _id
              user_location
              user_ms_oid
              user_email
              user_fname
              user_job_title
              user_lname
              user_mobile
              user_notes
              user_office_tel
              user_profile_image
              user_type {
                  _id
                  user_type_display_name
              }
              user_role {
                _id
                user_type
                user_type_id
                user_role_display
                user_role_code
              }
              user_duty_holder {
                _id
                dh_name
                dh_code
              }
              user_duty_holder_location{
                location_name
                location_address_line_1
                location_address_line_2
                location_address_city
                location_address_country
                location_address_post_zip_code
              }
              user_status
          }
      }
  `;

const ASSIGNED_DUTYHOLDERS_QUERY = gql`
query AssignedDutyholders($userId: String!){
  assignedDutyholders(userId: $userId) {
    dutyHolder {
      _id
      dh_code
      dh_name
      dh_status
      dh_archived
    }
    installations {
      _id
      installation_code
      installation_title
      installation_archived
      installation_status 
      dh_id{
        _id
      }
      ag_id{
        _id
      }
    }
    count
  }
}`;

const ASSIGNED_INSTALLATION_QUERY = gql`
query AssignedInstallations($dhId: String!, $userId: String!){
  assignedInstallations(dhId: $dhId, userId: $userId) {
    installation {
      _id
      installation_code
      installation_title
      installation_archived
      installation_status
      dh_id{
        _id
      }
      ag_id{
        _id
      }
    }
    seces {
      _id
      sece_title
      sece_ref
      sece_client_ref
      sece_criticality
      sece_owner
      sece_order_no
      sece_archived
      sece_status
      dh_id{
        _id
      }
      ag_id{
        _id
      }
      installation_id{
        _id
      }
      bar_id{
        _id
      }
    }
    count
  }
}`;

const ViewUserProfile = () => {
  const [userDhInstList, setUserDhInstList] = useState([]);
  const [userDutyholder, setUserDutyholder] = useState({});
  const [userInstSeceList, setUserInstSeceList] = useState([]);

  const [userRoles, setUserRoles] = useState([]);
  const [userObj, setUserObj] = useState([]);
  const [savedUserType, setSavedUserType] = useState(""); 
  const [userDHLocation, setUserDHLocation] = useState({});
  const [shouldSpin, setShouldSpin] = useState(false);

  const { userId = ""} = useParams();

  const [user, { error, data }] = useLazyQuery(USER_QUERY);

  const [assignedDutyholders, { error: assignedDhError, data: assignedDhData }] = useLazyQuery(ASSIGNED_DUTYHOLDERS_QUERY);
  const [assignedInstallations, { error: assignedInstError, data: assignedInstData }] = useLazyQuery(ASSIGNED_INSTALLATION_QUERY);
  
  useEffect(() => {
    if (userId && userId !== "") {
      user({ variables: { userId }, errorPolicy: 'all' });
      setShouldSpin(true)
    }
  }, [])

  useEffect(() => {
    if (savedUserType && savedUserType === "ABS" && userId !== "") {
      assignedDutyholders({ variables: { userId: userId }, errorPolicy: 'all' });
    }
    if (savedUserType && savedUserType === "Duty Holder" && userId !== "") {
      const { _id: dh_id = "" } = userDutyholder ? userDutyholder : {};

      assignedInstallations({ variables: { dhId: dh_id, userId: userId }, errorPolicy: 'all' });
    }
  }, [savedUserType]);

  useEffect(() => {
    if (assignedDhData && assignedDhData.assignedDutyholders) {
      let assignedDhInstList = formatABSUserData(assignedDhData.assignedDutyholders);
      setUserDhInstList(assignedDhInstList);
      setShouldSpin(false);
    }
  }, [assignedDhData]);

  useEffect(() => {
    if (assignedInstData && assignedInstData.assignedInstallations) {
      let assignedInstSeceList = formatDHUserData(assignedInstData.assignedInstallations);
      setUserInstSeceList(assignedInstSeceList);
      setShouldSpin(false);
    }
  }, [assignedInstData]);

  useEffect(() => {
    if (data && data.user) {    
      let tempUser = data.user;
      const { user_type, user_role, user_duty_holder, user_duty_holder_location } = tempUser;
      let tempUserRoles = user_role && user_role !== "" ? [user_role] : [];
      let tempUserType = user_type && user_type.user_type_display_name ? user_type.user_type_display_name : "N/A";
      setUserObj(tempUser);
      setUserRoles(tempUserRoles);
      setUserDutyholder(user_duty_holder);
      setUserDHLocation(user_duty_holder_location);
      setSavedUserType(tempUserType)
    }
  }, [data])

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: userObj.user_fname + userObj.user_lname, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'USER DETAILS', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: userObj.user_fname + userObj.user_lname, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'USER DETAILS', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'USER DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: userObj.user_fname + userObj.user_lname + ' - Details',
    },
    content: [

      {
        table: {
          widths: ['50%', '50%'],
          headerRows: 0,
          body: [
            [{ text: 'Name', style: 'StandardTableHeader', colSpan: 2 }, {}],
            [{ text: userObj.user_fname + " " + userObj.user_lname, colSpan: 2 }, {}],
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userObj.user_status],
            [{ text: 'Job Title', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userObj.user_job_title],
            [{ text: 'Email Address', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userObj.user_email],
            [{ text: 'Office Tel.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userObj.user_office_tel],
            [{ text: 'Mobile', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userObj.user_mobile],
          ]
        },
        style: 'standardTable',
      },

      {
        table: {
          widths: ['50%', '50%'],
          headerRows: 0,
          body: [
            [{ text: 'User Type', style: 'StandardTableHeader', colSpan: 2 }, {}],
            [{ text: savedUserType !== "" ? savedUserType : "N/A", colSpan: 2 }, {}],
          ]
        },
        style: 'standardTable',
      },

      (savedUserType === "Duty Holder" ?
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 0,
            body: [
              [{ text: 'Duty Holder', style: 'StandardTableHeader', colSpan: 2 }, {}],
              [{ text: userDutyholder && userDutyholder.dh_name ? userDutyholder.dh_name : "N/A", colSpan: 2 }, {}],
            ]
          },
          style: 'standardTable',
        } : ""
      ),

      (savedUserType === "Duty Holder" ?
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 0,
            body: [
              [{ text: 'Duty Holder Location', style: 'StandardTableHeader', colSpan: 2 }, {}],
              [{
                text: (userDHLocation && userDHLocation.location_name) ? (userDHLocation.location_name + "\n" +
                  userDHLocation.location_address_line_1 + "\n" +
                  (userDHLocation.location_address_line_2 !== "" && (userDHLocation.location_address_line_2 + "\n")) +
                  userDHLocation.location_address_city + "\n" +
                  userDHLocation.location_address_post_zip_code + "\n" +
                  userDHLocation.location_address_country + ".") : "No Location to Display",
                colSpan: 2

              }, {}
              ],
            ]
          },
          style: 'standardTable',
        } : ""
      ),

      ...(savedUserType === "ABS" && userDhInstList.length > 0 ? userDhInstList.map((item, index) => {
        return {
          table: {
            widths: ['100%'],
            headerRows: 0,
            body: [
              [{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
              [{ text: item.dh_name }],
              [{ text: 'Installations', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
              [{
                text: item.installations.length > 0 ? item.installations.map((inst, index) =>
                  ({ text: `${inst && inst.inst_title} \n` }),
                ) : []
              }],
            ]
          },
          style: 'standardTable',
        }
      }) : ""),

      ...(savedUserType && savedUserType === "Duty Holder" &&
        userInstSeceList.length > 0 ? userInstSeceList.map((item, index) =>
        ({
          table: {
            widths: ['100%'],
            headerRows: 0,
            body: [
              [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
              [{ text: item.inst_title }],
              [{ text: 'SECEs', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
              [{
                text: item.seces.length > 0 ? item.seces.map((sece, index) =>
                  ({ text: `${sece && sece.sece_title} \n` }),
                ) : []
              }],
            ]
          },
          style: 'standardTable',
        })
        ) : ""),

      (userRoles.length > 0 ? {
        table: {
          widths: ['100%'],
          headerRows: 0,
          body: [
            [{ text: 'Role', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
            [{
              text: userRoles.length > 0 ? userRoles.map((role, index) =>
                ({ text: `${role.user_role_display} \n` }),
              ) : { text: `${"N/A"}` }
            }],
          ]
        },
        style: 'standardTable',
      } : "")

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }
  
  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (shouldSpin) {
    return (
      <PageSpinner />
    )
  }

  if (error || assignedDhError || assignedInstError) {
    return (
      <span>Something went wrong retrieving the user data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{userObj.user_fname} {userObj.user_lname} Profile</h1>
      </div>

      {/* <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : `/users`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link> */}

      <button onClick={() => pdfTab()} className="mr-2 button-red">PDF</button>

      {userObj.user_profile_image &&
        <img src={userObj.user_profile_image} alt={`${userObj.user_fname}  ${userObj.user_lname}`}
          className="mt-6 rounded-full w-48 max-w-full h-auto align-middle" />
      }

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{userObj.user_fname} {userObj.user_lname}</h2>
          <h3 className="mb-3 font-bold text-red-900">{userObj.user_job_title}</h3>

          <p>Email Address: {userObj.user_email}</p>
          <p>Office Tel: {userObj.user_office_tel}</p>
          <p>Mobile: {userObj.user_mobile}</p>
        </div>

        <div className="mb-6 pb-6 border-b-2 border-gray-200">

          <table className="w-full mb-10 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">User Type</th>
              </tr>
              <tr>
                <td className="p-3 border">{savedUserType}</td>
              </tr>
            </tbody>
          </table>

          {savedUserType && savedUserType === "ABS" &&
            <>
              {userDhInstList.length > 0 && userDhInstList.map((item, index) =>
                <table className="w-full mb-10 table-auto" key={index}>
                  <tbody>
                    <tr>
                      <th className="p-3 text-left bg-blue-900 text-white">Duty Holder</th>
                    </tr>
                    <tr>
                      <td className="p-3 border"><Link to={item && item.dh_id && item.dh_id !== null ? `/dutyholders/view/${item.dh_id}` : "#"} className="font-sans text-blue-900 hover:underline">{item.dh_name}</Link></td>
                    </tr>
                    <tr>
                      <th className="p-3 text-left bg-blue-900 text-white">Installations</th>
                    </tr>
                    <tr>
                      <td className="p-3 border">
                        {item.installations && item.installations.length > 0 && item.installations.map((inst, index) =>
                          <React.Fragment key = {index}>
                            <Link to={`/dutyholder/${inst&&inst.dh_id}/asset-group/${inst&&inst.ag_id}/installation/${inst && inst.inst_id}/view`} className="font-sans text-blue-900 hover:underline">{inst && inst.inst_title}</Link>
                            <br />
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
          }

          {
            savedUserType && savedUserType === "Duty Holder" &&
            <>
              <table className="w-full mb-10 table-auto">
                <tbody>
                  <tr>
                    <th className="p-3 text-left bg-blue-900 text-white">Duty Holder</th>
                  </tr>
                  <tr>
                    {userDutyholder && userDutyholder.dh_name ?
                      <td className="p-3 border"><Link to={userDutyholder && userDutyholder._id !== null ? `/dutyholders/view/${userDutyholder._id}` : "#"} className="font-sans text-blue-900 hover:underline">{userDutyholder.dh_name}</Link></td>
                      : "No Duty Holder to Display"}
                  </tr>
                </tbody>
              </table>

              <table className="w-full mb-10 table-auto">
                <tbody>
                  <tr>
                    <th className="p-3 text-left bg-blue-900 text-white">Duty Holder Location</th>
                  </tr>
                  <tr>
                    {userDHLocation.location_name ?
                      <td className="p-3 border">
                        <p>{userDHLocation.location_name}</p>
                        <p>{userDHLocation.location_address_line_1}</p>
                        <p>{userDHLocation.location_address_line_2}</p>
                        <p>{userDHLocation.location_address_city}</p>
                        <p>{userDHLocation.location_address_post_zip_code}</p>
                        <p>{userDHLocation.location_address_country}.</p>
                      </td>
                      : <td className="p-3 border">{"No Location to Display"}</td>
                    }
                  </tr>
                </tbody>
              </table>

              {userInstSeceList.length > 0 && userInstSeceList.map((item, index) =>
                <table className="w-full mb-10 table-auto" key={index}>
                  <tbody>
                    <tr>
                      <th className="p-3 text-left bg-blue-900 text-white">Installation</th>
                    </tr>
                    <tr>
                      <td className="p-3 border"><Link to={`/dutyholder/${item && item.dh_id}/asset-group/${item && item.ag_id}/installation/${item && item.inst_id}/view`} className="font-sans text-blue-900 hover:underline">{item && item.inst_title}</Link></td>
                    </tr>
                    <tr>
                      <th className="p-3 text-left bg-blue-900 text-white">SECEs</th>
                    </tr>
                    <tr>
                      <td className="p-3 border">
                        {item.seces && item.seces.length > 0 && item.seces.map((sece, index) =>
                          <React.Fragment key={index}>
                            <Link to={`/dutyholder/${sece && sece.dh_id}/asset-group/${sece && sece.ag_id}/installation/${sece && sece.inst_id}/barrier/${sece && sece.bar_id}/sece/view/${sece && sece.sece_id}`} className="font-sans text-blue-900 hover:underline">{sece && sece.sece_title}</Link>
                            <br />
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
          }

          <table className="w-full mb-10 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">Role</th>
              </tr>
              <tr>
                {userRoles.length ? userRoles.map((role, index) =>
                  <td key={index} className="p-3 border">{role.user_role_display && role.user_role_display}</td>
                ) :
                  <td className="p-3 border">{"No Roles to Display"}</td>
                }
              </tr>
            </tbody>
          </table>
         
        </div>

        {userObj.user_notes &&
          <div className="mb-6">
            <h2 className="mb-3 font-bold text-xl text-blue-900">Notes</h2>
            <p>{userObj.user_notes}</p>
          </div>
        }

        {/* <Link to="/users" className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link> */}
        <button onClick={() => pdfTab()} className="mr-2 button-red">PDF</button>

      </div>

    </div>
  )

}

function formatABSUserData(assignedDhList) {
  let dhInstList = [];
  if (assignedDhList && assignedDhList.length) {
    assignedDhList.forEach(asDh => {
      const { dutyHolder, installations } = asDh ? asDh : {};
      const { _id:dhId, dh_name } = dutyHolder ? dutyHolder : {};
      let instList = installations && installations.length ? installations : [];
      let dhName = dh_name ? dh_name : "";
      let tempDhId = dhId ? dhId : null;

      let tempInstList = [];
      instList.forEach(inst => {
        const { _id: instId, dh_id, ag_id, installation_title } = inst ? inst : {};
        let instTitle = installation_title ? installation_title : "";
        let instDhId = dh_id && dh_id._id ? dh_id._id : null;
        let instAgId = ag_id && ag_id._id ? ag_id._id : null;
        let tempInstId = instId ? instId : null;
        if (instTitle !== "") {
          tempInstList.push({ dh_id: instDhId, ag_id: instAgId, inst_id: tempInstId, inst_title: instTitle });
        }
      });

      if (dhName !== "" && tempInstList.length > 0) {
        let dhInstItem = { dh_name: dhName, dh_id: tempDhId, installations: tempInstList };
        dhInstList.push(dhInstItem);
      }

      if (dhName === "" && tempInstList.length > 0) {
        let dhInstItem = { dh_name: "N/A", dh_id: tempDhId, installations: tempInstList };
        dhInstList.push(dhInstItem);
      }
    });
  }
  return dhInstList;
}

function formatDHUserData(assignedInstList) {
  let instSeceList = [];
  if (assignedInstList && assignedInstList.length) {
    assignedInstList.forEach(asInst => {
      const { installation, seces } = asInst ? asInst : {};
      const { _id: instId, installation_title, dh_id: instDhId, ag_id: agDhId } = installation ? installation : {};
      let seceList = seces && seces.length ? seces : [];
      let instTitle = installation_title ? installation_title : "";
      let tempInstId = instId ? instId : null;

      let tempSeceList = [];     
      seceList.forEach(sece => {
        const { dh_id, ag_id, installation_id, bar_id, _id: seceId, sece_title } = sece ? sece : {};
        let seceTitle = sece_title ? sece_title : "";
        let seceDhId = dh_id && dh_id._id ? dh_id._id : null;
        let seceAgId = ag_id && ag_id._id ? ag_id._id : null;
        let seceInstId = installation_id && installation_id._id ? installation_id._id : null;
        let seceBarId = bar_id && bar_id._id ? bar_id._id : null;

        let tempSeceId = seceId ? seceId : null;
        if (seceTitle !== "") {
          tempSeceList.push({ dh_id: seceDhId, ag_id: seceAgId, inst_id: seceInstId, bar_id: seceBarId, sece_id: tempSeceId, sece_title: seceTitle });
        }
      });

      if (instTitle !== "" && tempSeceList.length > 0) {
        let instSeceItem = {
          inst_id: tempInstId,
          inst_title: instTitle,
          dh_id: instDhId._id,
          ag_id: agDhId._id,
          seces: tempSeceList
        };
        instSeceList.push(instSeceItem);
      }

      if (instTitle === "" && tempSeceList.length > 0) {
        let instSeceItem = { inst_id: tempInstId, inst_title: "N/A", seces: tempSeceList };
        instSeceList.push(instSeceItem);
      }
    });
  }
  return instSeceList;
}

export default ViewUserProfile;
