/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { 
    useQueryString, 
    removeLastNthParamFrmUrl, 
    formatDateToLocaleDateString,
    capitaliseFirstCase } from '../../utils';

const PS_HISTORIES_QUERY = gql`
query PsHistoriesByDutyHolderId($dhId: String!, $filter: String){
    psHistoriesByDutyHolderId(dhId: $dhId, filter: $filter) {
        _id
        dh_id {
            _id
        }
        ps_ref
        ps_title
        ps_desc
        ps_criteria
        ps_status
        history_parent_record_id
        history_modifying_user_id{
           _id
           user_fname
           user_lname
           user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

function PerformanceStandardHistory(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [psHistories, setPsHistories] = useState([]);

    const { id: dhId = "", seceId = ""} = useParams();
    const queryString = useQueryString();
    const filters = queryString.get('filters');

    const { match: { url = "" } } = props ? props : {};

    const [psHistoriesByDutyHolderId, { loading, error, data: psHistoriesData }] = useLazyQuery(PS_HISTORIES_QUERY);

    useEffect(() => {
        let localFilter = filters ? filters : JSON.stringify({ ...(seceId && seceId !== "" && { sece_id: seceId }) });
        psHistoriesByDutyHolderId({ variables: { dhId, ...({ filter: localFilter }) }, errorPolicy: 'all' });
    }, [])

    useEffect(() => {
        if (psHistoriesData && psHistoriesData.psHistoriesByDutyHolderId) {
            let hisList = psHistoriesData.psHistoriesByDutyHolderId;
            let formatedHisList = formatPsHistoryData(hisList);
            let reversedHistoryList = formatedHisList.reverse();
            return setPsHistories(reversedHistoryList);
        }
    }, [psHistoriesData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'PS Name',
                accessor: 'name',
            },
            {
                Header: 'Modified Date',
                accessor: "history_modified_date"
            },
            {
                Header: 'Modifying User',
                accessor: "modifying_user"
            },
            {
                Header: 'Status',
                accessor: "status",
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, parentId } }) => (
                    <div className="text-right">
                        <Link
                            to={{
                                pathname: `${removeLastNthParamFrmUrl(url, 1)}/view-diff/${id}/${parentId}`,
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                        >
                            View Change
                        </Link>
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 mb-6 border-b-2 border-gray-200">

                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Performance Standard History<FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">A table detailing changes to Performance Standards.</p>
                }

            </div>

            <Link to={{ pathname: `${removeLastNthParamFrmUrl(url, 1)}` }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>

            <Table columns={columns} data={psHistories} />

        </div>
    )
}

function formatPsHistoryData(histories) {
    let historyList = []
  
    if (histories && histories.length) {
  
      histories.forEach(his => {
  
        const {_id, ps_title,ps_status, dh_id, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
        const { _id:dhId = ""} = dh_id ? dh_id : {};
        const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {};
        let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";
  
        let newHistory = {
          id: _id,
          parentId: history_parent_record_id,
          dhId: dhId,
          name: ps_title && ps_title !== "" ? ps_title : "N/A",
          history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
          modifying_user: userFullName,
          status: ps_status && ps_status !== "" ? capitaliseFirstCase(ps_status) : "N/A"
        }
  
        historyList.push(newHistory);
      });
    }
  
    return historyList;
  }

export default PerformanceStandardHistory;