/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject, formatDateToLocaleDateString} from "../../utils/index";

const COMPETENCY_HISTORIES_QUERY = gql`
query {
    competency_histories {
        _id
        comp_code
        comp_title
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;


function CompetencyHistory(props) {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);
    const [compHistories, setCompHistories] = useState([]);

    const [competency_histories, {loading, error, data:competencyHistoriesData}] = useLazyQuery(COMPETENCY_HISTORIES_QUERY);

    useEffect(() => {
      if (authContext && authContext.user){
         let result = createUserTypeAndRoleObject(authContext);
         setUserTypeAndRoleObject(result);
      }
    }, [authContext]);
  
    useEffect(() => {
      if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
          history.push('/access-permissions');
      }
    }, [userTypeAndRoleObject]);
  
    useEffect(() => {
        competency_histories({variables: {}, errorPolicy: 'all'});
    }, []);

    useEffect(() => {
        if(competencyHistoriesData && competencyHistoriesData.competency_histories){
            let histories = formatCompetencyHistories(competencyHistoriesData.competency_histories);
            let reversedHistory = histories.reverse();
            setCompHistories(reversedHistory);
        }
    }, [competencyHistoriesData]);

    const columns = React.useMemo(
        () => [
          {
            Header: 'Code',
            accessor: 'code'
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Modified Date',
            accessor: 'modified_date',
          },
          {
            Header: 'Modified By',
            accessor: 'modified_by'
          },
          {
            Header: '',
            id: 'buttons',
            accessor: originalRow => originalRow,
            Cell: ({value:{id, parentId}}) => (
              <div className="text-right">
                <Link
                  to={{
                    pathname: `/user-competency/competencies/history-diff/${id}/${parentId}`,
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  View Change
                </Link>
    
              </div>
            )
          },
        ],
        []
      )
    
      if (loading) {
        return (
          <PageSpinner/>
        )
      }
    
      if (error) {
        return (
          <span>Something went wrong retrieving the table data</span>
        )
      }
    
      return (
          <div className="w-full px-8 pb-8">

              <div className="pb-6 border-b-2 border-gray-200">

                  <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Competency Histories<FontAwesomeIcon
                      icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                      onClick={() => setShowHelp(!showHelp)} /></h1>

                  {showHelp &&
                      <p className="mt-3">A table detailing changes to competencies.</p>
                  }

              </div>

              <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

              <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competencies - Competencies - Histories</h6>

              <Link
                  to={{
                      pathname: '/user-competency/competencies',
                  }}
                  className="button-red mr-2 mb-2 py-3 px-5"
                >
                  <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
              </Link>

              <Table columns={columns} data={compHistories} />

          </div>
      )
}

function formatCompetencyHistories(competencyHistories) {

    let newCompetencyHistories = [];
    if (competencyHistories && competencyHistories.length) {
        competencyHistories.forEach(compHis => {

        const {user_fname:userFname="", user_lname:userLname=""} = compHis.history_modifying_user_id?compHis.history_modifying_user_id:{};
        
        let modifiedDateString = formatDateToLocaleDateString(compHis.history_modified_date) || "N/A";
  
        let competencyHistory = {
          id: compHis._id,
          code:compHis.comp_code, 
          name: compHis.comp_title,
          parentId:compHis.history_parent_record_id,
          modified_date: modifiedDateString,
          modified_by: (userFname + " " + userLname),
        };
  
        newCompetencyHistories.push(competencyHistory);
      })
    }
    return newCompetencyHistories;
}

export default CompetencyHistory;
