/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { 
    removeLastNthParamFrmUrl, 
    getSectionName, 
    createQueryObject, 
    capitaliseFirstCase,
    formatDateToLocaleDateString
} from '../../utils';

const REF_ACT_HISTORIES_QUERY = gql`
query ReferenceActivityHistoriesSearch($param: ReferenceActivityHistoriesSearchInput!){
    referenceActivityHistoriesSearch(param: $param) {
        _id
        ra_title
        ra_desc
        ra_sample_size
        ra_status
        history_parent_record_id
        history_modifying_user_id{
           _id
           user_fname
           user_lname
           user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

function ReferenceActivityHistory(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [raHistories, setRaHistories] = useState([]);

    const { match: { url = "" } } = props ? props : {};

    const [referenceActivityHistoriesSearch, { loading, error, data: raHistoriesData }] = useLazyQuery(REF_ACT_HISTORIES_QUERY);

    useEffect(() => {
        let queryObject = createQueryObject(url, 4);
        referenceActivityHistoriesSearch({ variables: { param: queryObject }, errorPolicy: 'all' });
    }, [])

    useEffect(() => {
        console.log('RaHistory data===>', raHistoriesData);
        if (raHistoriesData && raHistoriesData.referenceActivityHistoriesSearch) {
            let histories = formatRaHistories(raHistoriesData.referenceActivityHistoriesSearch);
            let reversedHistoryList = histories.reverse();
            setRaHistories(reversedHistoryList);
        }
    }, [raHistoriesData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Ref Activity Title',
                accessor: 'name',
            },
            {
                Header: 'Modified Date',
                accessor: 'modified_date'
            },
            {
                Header: 'Modified By',
                accessor: 'modified_by'
            },
            {
                Header: 'Status',
                accessor: "status",
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, parentId } }) => (
                    <div className="text-right">
                        <Link
                            to={{
                                pathname: `${removeLastNthParamFrmUrl(url, 1)}/view-diff/${id}/${parentId}`,
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                        >
                            View Change
                        </Link>
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 mb-6 border-b-2 border-gray-200">

                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{getSectionName(url, 4).replace("-", " ")} Reference Activity History<FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">A table detailing changes to Reference Activities</p>
                }

            </div>

            <Link to={{ pathname: `${removeLastNthParamFrmUrl(url, 1)}` }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>


            {raHistories.length ?
                <Table columns={columns} data={raHistories} />
                : <h4 className="mt-4">There are currently no Installations</h4>}

        </div>
    )
}

function formatRaHistories(raHistories) {

    let newRaHistories = [];
    if (raHistories && raHistories.length) {
        raHistories.forEach(raHis => {

            const { user_fname: userFname = "", user_lname: userLname = "" } = raHis.history_modifying_user_id ? raHis.history_modifying_user_id : {};
            let modifiedDateString = formatDateToLocaleDateString(raHis.history_modified_date) || "N/A";

            let raHistory = {
                id: raHis._id,
                name: raHis.ra_title,
                parentId: raHis.history_parent_record_id,
                modified_date: modifiedDateString,
                modified_by: (userFname + " " + userLname),
                status: raHis.ra_status && raHis.ra_status !== "" ? capitaliseFirstCase(raHis.ra_status) : "N/A"
            };

            newRaHistories.push(raHistory);
        })
    }
    return newRaHistories;
}

export default ReferenceActivityHistory;