/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject, formatDateToLocaleDateString} from "../../utils";

const LOCATION_HISTORIES_QUERY = gql`
query HistoriesByLocationIds($locationIds: [ID!]){
    historiesByLocationIds(_ids: $locationIds){
        _id
        location_name
        location_address_city
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

const DUTYHOLDER_QUERY = gql`
query DutyHolder($dutyHolderId: ID!){
     dutyHolder(_id: $dutyHolderId){
        _id
        dh_location
        dh_head_office{
            _id
            location_name
        }
    }
}`;

function LocationHistory(props) {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [locationHistoryList, setLocationHistoryList] = useState([]);
  const { featureName = "", featureId = "", locationId = "" } = useParams();

  const [dutyHolder, { data: dutyHolderData }] = useLazyQuery(DUTYHOLDER_QUERY);
  const [historiesByLocationIds, { loading, error, data: locationHistoriesData }] = useLazyQuery(LOCATION_HISTORIES_QUERY);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (featureId !== "" && locationId === "") {
      dutyHolder({ variables: { dutyHolderId: featureId }, errorPolicy: 'all' })
    }

    if (featureId !== "" && locationId !== "") {
      let locationIds = [locationId];
      historiesByLocationIds({ variables: { locationIds: locationIds }, errorPolicy: 'all' });
    }
  }, []);

  useEffect(() => {
    if (dutyHolderData && dutyHolderData.dutyHolder) {
      let locationIds = dutyHolderData.dutyHolder.dh_location ? dutyHolderData.dutyHolder.dh_location : [];
      historiesByLocationIds({ variables: { locationIds: locationIds }, errorPolicy: 'all' });
    }
  }, [dutyHolderData]);

  useEffect(() => {
    if (locationHistoriesData && locationHistoriesData.historiesByLocationIds) {
      let hisList = locationHistoriesData.historiesByLocationIds;
      if (locationId !== "") {
        let splicedHisList = hisList.splice(1, hisList.length).reverse();
        let histories = formatLocationHistories(splicedHisList);
        setLocationHistoryList(histories);
      } else {
        let histories = formatLocationHistories(hisList);
        setLocationHistoryList(histories);
      }
    }
  }, [locationHistoriesData]);

  /*
  const getBackLink = () => {
    if (featureName !== "" && featureId !== "") {
      let backLink = `/${featureName}/${featureId}/locations`
      return backLink;
    }
  } */

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'name'
      },
      {
        Header: 'Name',
        accessor: 'city',
      },
      {
        Header: 'Modified Date',
        accessor: 'modified_date',
      },
      {
        Header: 'Modified By',
        accessor: 'modified_by'
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, parentId } }) => (
          <div className="text-right">
            <Link
              to={`/${featureName}/${featureId}/locations/history-diff/${id}/${parentId}`}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>

          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Location Histories<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table detailing changes to locations.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Duty Holder - Locations - Histories</h6>

      {/*
      <Link
        to={getBackLink()}
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
      </Link>
      */ }

      <Table columns={columns} data={locationHistoryList} />

    </div>
  )
}

function formatLocationHistories(locationHistories) {

  let newLocationHistories = [];
  if (locationHistories && locationHistories.length) {
    locationHistories.forEach(locHis => {
      let modifyingUser = locHis.history_modifying_user_id;

      if (modifyingUser) {
        const { user_fname: userFname = "", user_lname: userLname = "" } = modifyingUser;

        let modifiedDateString = formatDateToLocaleDateString(locHis.history_modified_date) || "N/A";

        let locationHistory = {
          id: locHis._id,
          name: locHis.location_name,
          city: locHis.location_address_city,
          parentId: locHis.history_parent_record_id,
          modified_date: modifiedDateString,
          modified_by: (userFname + " " + userLname),
        };

        newLocationHistories.push(locationHistory);
      }
    })
  }
  let reversedHistories = newLocationHistories.reverse();
  return reversedHistories;
}

export default LocationHistory;
