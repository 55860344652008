/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useContext, useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {Link, useHistory, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {omit} from "lodash";
import {AuthContext} from "../../context/authContext";
import FileUpload from '../global/FileUpload';
import PageSpinner from '../global/pageSpinner';
import {parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const Validation = Yup.object().shape({
  dh_code: Yup.string().matches(/^[A-Z]{3}$/, 'Must be exactly 3 Uppercase Characters').label("dh_code").required("Duty Holder Code is required"),
  dh_name: Yup.string().label("dh_name").required("Duty Holder Name is required"),
  dh_description: Yup.string().label("dh_description").required("Description is required"),
})

const UPDATE_DUTY_HOLDER = gql`
    mutation UpdateDutyHolder($id: String!, $dutyHolder: DutyHolderInput!, $userId: String!) {
        updateDutyHolder(_id: $id, dutyHolder: $dutyHolder, userId: $userId) {
            dh_code
            dh_name
            dh_location
            dh_description
            success
            status_code
            message
        }
    }`;

const DUTY_HOLDER_QUERY = gql`
    query DutyHolder($dhId: ID!){
        dutyHolder(_id: $dhId) {
            dh_code
            dh_name
            dh_description
            dh_added_date
            dh_archived_date
            dh_attachments
        }
    }
`;

const AddDutyHolder = (props) => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);

  const [formInitValues, setFormInitValues] = useState({ _id: '', dh_code: '', dh_name: '', dh_description: '', dh_attachments: '[]' });
  const { id: dhId = '' } = useParams();
  const { location: { state = "" }} = props ? props : {};

  const [dutyHolder, {data: dutyHolderData}] = useLazyQuery(DUTY_HOLDER_QUERY);
  const [updateDutyHolder, {loading: loadingUpdateDh, error: errorUpdateDh, data: updateDutyHolderData}] = useMutation(UPDATE_DUTY_HOLDER);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (dhId !== '') {
      dutyHolder({variables: {dhId}, errorPolicy: 'all' })
    }
  }, [])

  useEffect(() => {
    if (dutyHolderData && dutyHolderData.dutyHolder) {
      console.log("dutyHolderData.dutyHolder>>>>", dutyHolderData.dutyHolder);
      setFormInitValues(dutyHolderData.dutyHolder)
    }

  }, [dutyHolderData])

  useEffect(() => {
    if (updateDutyHolderData) {
      const { success, message } = updateDutyHolderData.updateDutyHolder;
      if (!success) {
        alert(message);
      }
      if (success) {
        history.push('/dutyholders');
      }
    }
  }, [updateDutyHolderData]);

  const displayBackLink = (state) => {
    return(
      <Link to={{
        pathname:"/dutyholders" + (state&&state.archivedStatus !==""? `/${state.archivedStatus}`:""),
        state: state&&state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  }

  let tempDhAttachFileList = [];
  const handleDhAttachFilesDescription = (item) => {
    let index = tempDhAttachFileList.findIndex(f => f.name === item.name);
    if (index >= 0) {
      tempDhAttachFileList[index] = item;
    } else {
      tempDhAttachFileList.push(item);
    }
  };

  if (loadingUpdateDh) {
    return (
      <PageSpinner displayText={"submitting form"} />
    )
  }

  if (errorUpdateDh) {
    return (
      <span>Something went wrong saving duty holder</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Edit Duty Holder <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
            <p>Enter the details into the required fields.</p>
            <p>Once complete, click 'Edit Duty Holder'.</p>
        </div>
        }        
      </div>

      <Formik
        enableReinitialize
        initialValues={omit(formInitValues, '__typename')}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          const { _id: userId = "" } = authContext.user ? authContext.user : {};
          if(dhId !=='' && userId !=="" ){
            let dhAttachments = updateAttachmentData(values.dh_attachments, tempDhAttachFileList);
            let tempValues = {...values, dh_attachments : dhAttachments};
            updateDutyHolder({variables: {id: dhId, dutyHolder: tempValues, userId: userId}})
          }
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            setFieldTouched,
            isSubmitting
          }) => (
          <form onSubmit={handleSubmit}>

            <div className="my-4">
              {displayBackLink(state)}
            </div>

            <div>
              <label htmlFor="dh_code" className="block mb-1 text-blue-900 font-semibold">Duty Holder Code (3
                Letters)*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_code"
                id="dh_code"
                placeholder="Duty Holder Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_code}
              />
              <FormError touched={touched.dh_code} message={errors.dh_code}/>
            </div>

            <div>
              <label htmlFor="dh_name" className="block mb-1 text-blue-900 font-semibold">Duty Holder Name*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_name"
                id="dh_name"
                placeholder="Duty Holder Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_name}
              />
              <FormError touched={touched.dh_name} message={errors.dh_name}/>
            </div>

            <div>
              <label htmlFor="dh_description" className="block mb-1 text-blue-900 font-semibold">Description*</label>
              <textarea
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_description"
                id="dh_description"
                placeholder="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_description}
              />
              <FormError touched={touched.dh_description} message={errors.dh_description}/>
            </div>

            <div>
              <label htmlFor="dh_attachments" className="block mb-1 text-blue-900 font-semibold">Attachments</label>

              {authContext.user && authContext.user.user_home_dir &&
                <FileUpload
                  onAttach={(files) => setFieldValue('dh_attachments', JSON.stringify(files))}
                  id='fileManager'
                  buttonText='Attach Files'
                  apiURL={REACT_APP_FILE_MANAGER_API}
                  toolbarText='Attach'
                  homeDir={`/${authContext.user.user_home_dir}/`}
                  showDescription={true}
                  handleFileDescription={handleDhAttachFilesDescription}
                  tempFileList={tempDhAttachFileList}
                  {...({ attachmentList: parseJSONSafely(values.dh_attachments) ? parseJSONSafely(values.dh_attachments) : [] })}
                />
              }
              <FormError touched={touched.dh_attachments} message={errors.dh_attachments} />

            </div>

            <button type="submit" className="mt-3 button-red capitalize">Edit Duty Holder</button>

          </form>
        )}
      </Formik>

    </div>
  )

}

export default AddDutyHolder;
